import React from 'react';
import {
    List,
    ListItem,
    ListItemText,
    makeStyles,
    withStyles,
    Menu,
    MenuItem,
    Link
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import user from '../../../assets/images/user-logo.png';
import { service } from '../../utils';
import { getLocalizedPath } from '../../i18n/functions';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { userActions } from '../../redux/user';

const useStyle = makeStyles(theme => ({
    navigation: {
        display: 'flex',
        justifyContent:'space-between',
        alignItems: 'center',
        '& .MuiList-root': {
            padding: theme.spacing(0)
        },
        '& .MuiListItemText-root': {
            margintop: theme.spacing(1.4)
        }
    },
    link: {
        position: 'relative',
        display: 'inline-block',
        textDecoration: 'none',
        color: theme.palette.text.primary,
        padding: theme.spacing(0, 1.1),
        verticalAlign: 'middle',
        height: 'auto',
        lineHeight: theme.spacing(1),
       
        
    },
    loginLink: {
        position: 'relative',
        display: 'inline-block',
        textDecoration: 'none',
        color: '#fff',
        padding: theme.spacing(0, 2.25),
        verticalAlign: 'middle',
        height: 'auto',
        lineHeight: theme.spacing(1),
        background: '#575656',
        borderRadius: '4px'
    },
    subLink: {
        textDecoration: 'none',
        color: theme.palette.text.primary,
        fontSize: 14,
        verticalAlign: 'middle'
    },
    listItemText: {
        position: 'relative',
        fontSize: 14,
        margin: 0,
        verticalAlign: 'top',
        cursor: 'pointer'
    },
    myIcon: {
        verticalAlign: 'middle'
    },
    listItems: {
        padding: 0,
        color:'#fff',
    }
}));
const StyledMenu = withStyles({
    paper: {
      border: '1px solid #ddd',
    },
  })((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        disableScrollLock= {true}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
      {...props}
    />
));
const Navigation = ({ isLoggedIn, userData, actions }) => {
    const { t, i18n } = useTranslation('app');
    const preventDefault = event => event.preventDefault();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [anchorSignUp, setAnchorSignUp] = React.useState(null);
    const handleClicksignUp = event => {
        setAnchorSignUp(event.currentTarget);
    };
    const handleSignUpClose = () => {
        setAnchorSignUp(null);
    };

    //login
    const [anchorLogin, setAnchorLogin] = React.useState(null);
    const handleClickLogin = event => {
        setAnchorLogin(event.currentTarget);
    };
    const handleLoginClose = () => {
        setAnchorLogin(null);
    };

    React.useEffect(() => {
        const checkUserData = () => {
            if(isLoggedIn === null || isLoggedIn !== JSON.parse(localStorage.getItem('isLoggedIn'))) {
                service.get(getLocalizedPath('get-session-data',i18n))
                    .then(response => {
                        if(typeof response.data !== 'undefined' && typeof response.data.data !== 'undefined' && typeof response.data.data.firstName !== 'undefined') {
                            localStorage.setItem('isLoggedIn', true);
                            actions.userLoggedIn(response.data.data);
                        } else {
                            localStorage.setItem('isLoggedIn', false)
                            actions.userLoggedOut();
                        }
                    })
                    .catch(err => console.log(err));
            }
        }

        //run only initailly
        if(typeof userData.firstName === 'undefined') {
            checkUserData();
        }

        window.addEventListener('storage', checkUserData);

        return () => {
            window.removeEventListener('storage', checkUserData);
        }
    }, [isLoggedIn]);

    const classes = useStyle();
    
    return (
        <div className={classes.navigation}>
            <List>
                {/* <Link href={getLocalizedPath('about', i18n)} className={classes.link}>
                    <ListItem className={classes.listItems}>
                        <ListItemText classes={{ primary: classes.listItemText }}>
                            {t('about', 'About')}
                        </ListItemText>
                    </ListItem>
                </Link> */}
                <Link to="#" className={classes.link}>
                    <ListItem
                        className={classes.listItems}
                        aria-controls="products"
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <ListItemText classes={{ primary: classes.listItemText }}>
                            {t('Products', 'Products')}
                        </ListItemText>
                    </ListItem>
                    <StyledMenu
                        id="products"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem>
                            <Link href={getLocalizedPath('car-loan', i18n)} className={classes.subLink}>
                                {t('Car Loan', 'Car Loan')}
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link href={getLocalizedPath('bike-loan', i18n)} className={classes.subLink}>
                                {t('Bike Loan', 'Bike Loan')}
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link href={getLocalizedPath('scooter-loan', i18n)} className={classes.subLink}>
                                {t('Scooter Loan', 'Scooter Loan')}
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link href={getLocalizedPath('dealer-financing', i18n)} className={classes.subLink}>
                                {t('Dealer Financing', 'Dealer Financing')}
                            </Link>
                        </MenuItem>
                    </StyledMenu>
                </Link>
                <Link href={getLocalizedPath('emi-calculator', i18n)} className={classes.link}>
                    <ListItem className={classes.listItems}>
                        <ListItemText classes={{ primary: classes.listItemText }}>
                            {t('EMI Calculator', 'EMI Calculator')}
                        </ListItemText>
                    </ListItem>
                </Link>
                <Link href={getLocalizedPath('credit-score-assessment', i18n)} className={classes.link}>
                    <ListItem className={classes.listItems}>
                        <ListItemText classes={{ primary: classes.listItemText }}>
                            {t('Get Credit Score', 'Get Credit Score')}
                        </ListItemText>
                    </ListItem>
                </Link>
                <Link href={getLocalizedPath('apply-loan', i18n)} className={classes.link}>
                    <ListItem className={classes.listItems}>
                        <ListItemText classes={{ primary: classes.listItemText }}>
                            {t('Borrower', 'Borrower')}
                        </ListItemText>
                    </ListItem>
                </Link>
                <Link href={getLocalizedPath('lenders', i18n)} className={classes.link}>
                    <ListItem className={classes.listItems}>
                        <ListItemText classes={{ primary: classes.listItemText }}>
                            {t('Lender', 'Lender')}
                        </ListItemText>
                    </ListItem>
                </Link>
                <Link href={getLocalizedPath('merchant-signup', i18n)} className={classes.link}>
                    <ListItem className={classes.listItems}>
                        <ListItemText classes={{ primary: classes.listItemText }}>
                            {t('Merchant', 'Merchant')}
                        </ListItemText>
                    </ListItem>
                </Link>
                <Link href={getLocalizedPath('about', i18n)} className={classes.link}>
                    <ListItem className={classes.listItems}>
                        <ListItemText classes={{ primary: classes.listItemText }}>
                            {t('about', 'About')}
                        </ListItemText>
                    </ListItem>
                </Link>
                {
                isLoggedIn && userData.firstName
                    ?
                    <Link className={classes.loginLink}>
                        <ListItem
                            className={classes.listItems}
                            aria-controls="Login"
                            aria-haspopup="false"
                            onClick={handleClickLogin}
                        >
                            <ListItemText classes={{ primary: classes.listItemText }}>
                                {userData.firstName}
                            </ListItemText>
                        </ListItem>
                        <StyledMenu
                            id="login"
                            anchorEl={anchorLogin}
                            keepMounted
                            open={Boolean(anchorLogin)}
                            onClose={handleLoginClose}
                        >
                            <MenuItem onClick={handleLoginClose}>
                                <Link href={userData.isLender ? '/lender-dashboard' : getLocalizedPath('account/loan-applications', i18n)} className={classes.subLink}>
                                    {t('My Account', 'My Account')}
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleLoginClose}>
                                <Link href={getLocalizedPath('logout', i18n)} className={classes.subLink}>
                                    {t('Logout', 'Logout')}
                                </Link>
                            </MenuItem>
                        </StyledMenu>
                    </Link>
                    :
                    <>
                    {/* <Link to="#" className={classes.link}>
                        <ListItem
                            className={classes.listItems}
                            aria-controls="signUp"
                            aria-haspopup="true"
                            onClick={handleClicksignUp}
                        >
                            <ListItemText classes={{ primary: classes.listItemText }}>
                                 {t('Sign Up', 'Sign Up')}
                            </ListItemText>
                        </ListItem>
                        <StyledMenu
                            id="signUp"
                            anchorEl={anchorSignUp}
                            keepMounted
                            open={Boolean(anchorSignUp)}
                            onClose={handleSignUpClose}
                        >
                            <MenuItem onClick={handleSignUpClose}>
                                <Link href={getLocalizedPath('lenders', i18n)} className={classes.subLink}>
                                    {t('Sign Up As Lender', 'Sign Up As Lender')}
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleSignUpClose}>
                                <Link href={getLocalizedPath('apply-loan', i18n)} className={classes.subLink}>
                                    {t('Sign Up As Borrower', 'Sign Up As Borrower')}
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleSignUpClose}>
                                <Link href={getLocalizedPath('merchant-signup', i18n)} className={classes.subLink}>
                                    {t('Sign Up As Merchant', 'Sign Up As Merchant')}
                                </Link>
                            </MenuItem>
                        </StyledMenu>
                    </Link> */}
                    </>
                }
            </List>
            <List>
            {!userData?.firstName && (
                <Link
                    href={getLocalizedPath(isLoggedIn ? 'logout' : 'login', i18n)}
                    className={classes.link}
                >
                    <ListItem className={classes.listItems}>
                        <ListItemText classes={{ primary: classes.listItemText }}>
                            <img
                                className={classes.myIcon}
                                src={user}
                                alt={isLoggedIn ? 'SignOut' : 'SignIn'}
                            /> {t(isLoggedIn ? 'Logout' : 'Login', isLoggedIn ? 'Logout' : 'Login')}
                        </ListItemText>
                    </ListItem>
                </Link>
            )}
            </List>
        </div>
    );
}

const mapStateToProps = state => ({
    isLoggedIn: state.user.isLoggedIn,
    userData: state.user.userData
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(userActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);


Navigation.propTypes = {
    isLoggedIn: PropTypes.bool,
    userData: PropTypes.object.isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired
}

